@import "../../../assets/styles/global";

.nav_wrapper {
  width: 100%;
  background-color: #1d2126;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .nav_logo {
    position: relative;
    & > div {
      position: relative;
    }
    svg {
      width: 100%;
      position: inherit;
    }
  }
  .nav_menu {
  }
  .fixed_height {
    min-height: 123px;
    justify-content: center !important;
    align-items: center !important;
    box-sizing: border-box;
  }
  & > div:last-of-type {
    margin-top: auto;
  }

  .nav_icon {
    width: 30px;
    display: flex;
    justify-content: flex-start;
  }

  .divider {
    margin-top: 24px;
    margin-bottom: 24px;
    width: 100%;
    height: 2px;
    background-color: #2b2f33;
  }

  .nav_link_wrapper {
    cursor: pointer;
    display: flex;
    padding: 15px 16px;
    align-items: center;
    margin: 0px 10px;
    text-decoration: none;

    div {
      color: #8c969b !important;
      font-family: ManropeMedium;
      font-size: 16px;
      font-style: normal;
      line-height: 22px;
      margin-left: 10px;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }

    &.active_nav_menu {
      border-left: 3px solid #FFA726;
      margin-left: 6px;
      svg path {
        fill: #FFA726;
      }
      div {
        color: #FFA726 !important;
      }
    }
    &.nav_link_exit {
      svg {
        transform: rotate(90deg);
        path {
          fill: rgb(161, 171, 178);
        }
      }
    }
  }

  .navbar_footer {
    .block_item {
      padding: 20px;
      border-top: 1px solid #32373d;
    }
    .run_trades_container {
      display: flex;
      flex-direction: column;
    }
    .run_trades_parent {
      display: flex;
      // display: none;
      align-items: flex-start;
      flex-direction: column;
      justify-content: space-between;
      &.bg_active {
        background: #213628;
      }
    }
    .run_trades {
      color: #f6f9fe;
      font-family: ManropeMedium;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .run_trades_text {
      color: #f4f5f7;
      font-family: ManropeMedium;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 5px;
    }

    .support {
      font-family: ManropeMedium;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: #fff;
      a {
        color: #FFA726;
        text-decoration: none;
      }
    }
    .copyright {
      color: #8c969b;
      font-family: ManropeMedium;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      margin-top: 10px;
      a {
        color: #FFA726;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
.market_off {
  color: #a1abb2;
  font-family: ManropeMedium;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
.market_on {
  color: #20b053;
  font-family: ManropeMedium;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
.logout_button {
  background-color: #e23d3d !important;
  color: #fff !important;
  border: none !important;
}
.logout_header {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #1d2126;
}
.custom_modal_container {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  min-width: 400px;
  position: relative;
  padding: 20px;
  font-family: ManropeMedium;

  .cancel_button {
    background-color: #e23d3d !important;
    color: #333333 !important;
    border: none !important;
    min-width: 215px;
    // &:hover {
    //     // .size-l.btn-secondary:hove
    //     background-color: #b82222 !important;
    // }
  }
  .setting_modal_title {
    display: flex;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
    font-family: ManropeMedium;
    color: #1d2126;
  }
  .modal_content {
    width: 100%;
    box-sizing: border-box;
    padding: 0px;
    margin-bottom: 20px;
    p {
      font-family: ManropeMedium;
    }
  }

  .action_buttons {
    button {
      padding: 19px 22px;
    }
  }

  .approve_button {
    background-color: #1d2126 !important;
    color: #fff !important;
    border: none !important;
    min-width: 215px;
    &:hover {
      // .size-l.btn-secondary:hove
      background-color: #35383b !important;
    }
  }

  .cancel_button {
    color: #8c969b !important;
    border: none !important;
    &:hover {
      // .size-l.btn-secondary:hove
      background-color: #35383b !important;
    }
  }
}

.custom_modal_container_start_market {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;

  position: relative;
  padding: 20px 40px;

  div {
    outline: none !important;
    overflow-y: visible !important;
  }

  .close_icon {
    position: absolute;
    right: 0px;
    top: 0px;
    margin: 20px;
    cursor: pointer;
  }

  .start_market_modal_title {
    color: #1d2126;
    font-family: ManropeMedium;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 25.2px;
  }

  .star_market_modal_content {
    color: #1d2126;
    text-align: center;
    font-family: ManropeMedium;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    label {
      margin-bottom: 20px;
      display: block;
    }
    input {
      max-width: 150px;
    }
    .inputs_container {
    }
  }

  .approve_button {
    background-color: #1d2126 !important;
    color: #fff !important;
    border: none !important;
    min-width: 215px;
    &:hover {
      // .size-l.btn-secondary:hove
      background-color: #35383b !important;
    }
  }

  .cancel_button {
    color: #333333 !important;
    border: none !important;
    &:hover {
      // .size-l.btn-secondary:hove
      background-color: #35383b !important;
    }
  }
  .setting_modal_title {
    display: flex;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    padding: 20px 0px 0px;
  }
  .modal_content {
    width: 100%;
    box-sizing: border-box;
    padding: 0px;
  }

  .action_buttons {
    button {
      padding: 19px 22px;
    }
    .btn-tertiary {
      color: #8c969b;
    }
  }
}

.no_hours {
  font-style: italic;
  color: #dd4b4b;
  font-family: ManropeMedium;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
.start_market {
  display: flex;
  padding: 11px 14px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  color: #fff !important;
  background-color: #2f80ed !important;
}
.stop_market {
  display: flex;
  padding: 11px 14px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  color: #fff !important;
  border: 1px solid #e23d3d !important;
  background-color: #e23d3d !important;
}
.apply_button {
  display: flex;
  padding: 11px 14px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  color: #1d2126 !important;
  border: 1px solid #1d2126 !important;
}

.notification_circle {
  //   height: 20;
  //   width: 29px;
  height: fit-content;
  background-color: #ff9700;
  color: white;
  border-radius: 30px;
  //   padding: 4px 6px 4px 6px;
  padding-left: 6px;
  padding-right: 4px;
  font-family: ManropeMedium;
  font-size: 12px;
  min-width: 27px;
  min-height: 22px;
  text-align: center;
}
.merchantBank {
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: center;
  margin-top: -10px;
  border-top: 1px solid #2b2f33;
  padding-top: 20px;
  color: #fff;
  img {
    width: 100%;
    max-width: 150px;
  }
  b {
    margin-top: 10px;
  }
}
