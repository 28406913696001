@import "../../assets/styles/global";

.mainLoginSection {
    width: 100%;
    height: 100%;
    background-color: white;

    .grid_wrapper {
        display: flex;
        flex-direction: row;
        .bg_image_container {
            width: 55%;
            height: 100vh;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .blur_bg {
            height: 100%;
            background-image: url(../../assets/media/images/signin-bg-image.png);
            background-position: 10% center;
            background-repeat: no-repeat;
            background-size: cover;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
        }
        .signin_container {
            width: 45%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100vh;

            .mt_5 {
                margin-top: 5px;
            }

            .mb_24 {
                margin-bottom: 24px;
            }
            .login_button {
                margin-top: 10px;
            }

            .main_container {
                display: flex;
                flex-direction: column;
                align-items: center;
                max-width: 490px;
                width: 90%;
            }
            .headingFirst {
                margin-bottom: 20px;
            }
            .copyright {
                color: #8c969b;
                text-align: center;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                position: absolute;
                bottom: 10px;
            }
        }
        .error_container {
            margin-top: 10px;
            font-weight: 700;
            color: #db4c4c;
        }
    }
}
.forget_password {
    display: flex;
    justify-content: flex-end;
    color: #2f80ed;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 15px;
    width: 100%;
    cursor: pointer;
}
.select_lang_container_parent {
    width: fit-content;
    position: absolute;
    top: 0px;
    right: 0px;
    margin: 15px;
    .select_lang_container {
        position: relative;
        background-color: #fafbfc;
        & > svg {
            position: absolute;
            top: 10px;
            left: 4px;
        }
        & > div {
            outline: none !important;
            svg {
                fill: #5b636d;
            }
            & > div {
                padding: 10px 30px 10px 30px;
                // border: 2px solid #e1e6ed;
                color: #1d2126;
                font-weight: 500;
            }
        }
    }
}
@media screen and (max-width: 500px) {
    @include sign-page-adaptive;
}

.login_button {
    width: 100% !important;
    height: 50px!important;
}
