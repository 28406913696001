@import "../../../assets/styles/global";

.main_section {
  background-color: #f7f6f2;

  .main_grid {
    height: 100vh;
    @include grid-main;

    .w100 {
      margin-left: 20px;
      margin-right: 20px;
      padding: 15px;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
      // padding: 20px;
      &.background_none {
        background: none !important;
        box-shadow: none !important;
        border: none !important;
      }
      background: #F7F7F5 !important;
      border-radius: var(--radius-sm, 12px);
      // border: 1px solid var(--colors-sys-light-neutral-def-outline, #e6e6e6);
      box-shadow: none !important;
    }
  }
}

.breadcrumbs {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
}

.breadcrumb_link {
  color: #E5891D;
  text-decoration: none;
  font-size: 14px;
  line-height: 20px;
  
  &:hover {
    text-decoration: underline;
  }
}

.breadcrumb_separator {
  color: var(--colors-content-grey-text);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.breadcrumb_current {
  color: var(--colors-content-grey-text);
  font-size: 14px;
  line-height: 20px;
}

.app_main_layout {
  .main_section {
    background-color: #f7f6f2;

    .main_grid {
      height: 100vh;
      @include grid-main;

      .w100 {
        margin-left: 20px;
        margin-right: 20px;
        padding: 15px;
        border: 1px solid #e0e0e0;
        border-radius: 4px;
        // padding: 20px;
        &.background_none {
          background: none !important;
          box-shadow: none !important;
          border: none !important;
        }
        background: #F7F7F5 !important;
        border-radius: var(--radius-sm, 12px);
        // border: 1px solid var(--colors-sys-light-neutral-def-outline, #e6e6e6);
        box-shadow: none !important;
      }
    }
  }
}
