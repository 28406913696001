@import "./assets/styles/global.scss";

@font-face {
  font-family: "ManropeRegular";
  src:
    local("ManropeRegular"),
    url("./assets/fonts/manrope/Manrope-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "ManropeMedium";
  src:
    local("ManropeMedium"),
    url("./assets/fonts/onest/Onest-Medium.ttf") format("truetype");
  font-weight: 500;
}

body {
  font-family: ManropeMedium;
  margin: 0;
  width: 100%;
  height: 100%;
  @include custom-scroll;
  background: #F7F7F5;
  overflow-x: hidden !important;
}

.kyc-super-section {
  flex-direction: column;
  display: flex;
  width: 100%;
  margin: 0 auto;
  &-no-scroller {
    overflow: hidden;
  }
  @include desktop-and-above {
    min-width: 1200px;
    display: flex;
  }
}

.MuiDataGrid-cell:focus {
  outline: none !important;
}

.button-container {
  height: 48px !important;

  button {
    height: 100% !important;
  }
}
* {
  font-family: ManropeMedium !important;
}
.cancel_button {
  background-color: #e23d3d !important;
  color: #333333 !important;
  border: none !important;
  &:hover {
    // .size-l.btn-secondary:hove
    background-color: #b82222 !important;
  }
}

// .notistack-SnackbarContainer {
//   .notistack-Snackbar {
//     & > div {
//       background-color: #fff;
//       padding: 0px 10px;
//     }
//     #notistack-snackbar {
//       width: 100%;
//       padding: 0px ;
//     }
//   }
// }

.MuiInputBase-root {
  border-radius: 12px !important;
  border: 1px solid #e6e6e6;
  border: none;
}

.MuiPaper-root, .MuiPaper-elevation {
  border-radius: 12px !important;
}

.MuiSelect-select {
  display: flex !important;
  justify-content: flex-start;
}
