/* client-details.module.scss */

.info_block {
  background-color: #fff;
  border: 1px solid #e1e6ed;
  margin-top: -1px;
  margin-bottom: 20px;
  width: 50%;
  border-radius: 6px;

  .info_block_title {
    background-color: #f4f5f7;
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  .info_block_input_container {
    display: flex;
    min-height: 50px;
    align-items: center;
    padding: 0px 15px;
    border-bottom: 1px solid #e1e6ed;

    &:last-child {
      border-bottom: none;
    }

    & > label {
      width: 50%;
      display: flex;
      align-items: center;
      color: #5b636d;
    }

    & > div {
      flex: 1;
    }
  }

  .block_value {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    color: #5b636d;
  }
}

.breadcrumb_link {
  color: #e5891d;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    text-decoration: underline;
  }
}

.breadcrumb_separator {
  color: var(--colors-content-grey-text);
  margin: 0 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  align-items: center;
}

.custom_tabs_container {
  margin-top: 20px;

  .tabs {
    display: flex;
    width: 100%;
    max-width: 405px;
    border-top: 1px solid var(--colors-content-grey-border, #e1e6ed);
    border-right: 1px solid var(--colors-content-grey-border, #e1e6ed);
    border-left: 1px solid var(--colors-content-grey-border, #e1e6ed);
    border-radius: 12px 12px 0px 0px;

    .tab_button {
      flex: 1;
      padding: 9px 16px;
      background: #f8f9fa;
      border: none;
      font-size: 16px;
      cursor: pointer;
      text-align: center;
      transition: 0.3s;
      border-radius: 12px 0px 0px 0px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background: #f4f5f7;
      }

      &.active_tab {
        background: var(--tab-selected, #ffa726);
        color: #fff;
      }
    }

    .second_tab_button {
      flex: 1;
      padding: 9px 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      font-size: 16px;
      cursor: pointer;
      text-align: center;
      transition: 0.3s;
      border-radius: 0px 12px 0px 0px;

      background: var(--tab-default, #f7f7f5);

      &:hover {
        background: #f4f5f7;
      }

      &.active_tab {
        background: var(--tab-selected, #ffa726);
        color: #fff;
      }

      &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }

  .tab_content {
    padding: 16px;
    border-radius: 0px 12px 12px 12px;
    border: 1px solid var(--colors-sys-light-neutral-def-outline, #e6e6e6);
    background: var(--colors-sys-light-neutral-surf, #f7f7f5);
    height: 40vh;
  }
}

.products_container {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;

  .product_card {
    border-radius: var(--radius-sm, 12px);
    border: 1px solid var(--colors-sys-light-neutral-def-outline, #e6e6e6);
    background: var(--box-white, #fff);
    width: 100%;
    max-width: 293px;

    .top_wrapper_card {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 16px;
    }

    h3 {
      margin-bottom: 10px;
      color: #333;
    }

    p {
      margin-bottom: 15px;
      font-size: 20px;
      color: #666;
    }
  }
}

.details_button {
  width: 100%;
  background: var(--colors-sys-light-prim-prim, #333);
  color: white;
  border: none;
  padding: 15px 16px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background: #4b4848;
  }

  &:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
}

.back_button {
  margin-bottom: 10px;
  cursor: pointer;
  width: fit-content;
  display: flex;
  align-items: center;
}

.sessionsTableContainer {
  border-radius: 12px;
  border: 1px solid var(--colors-sys-light-neutral-def-outline, #e6e6e6);
  height: 35vh;
  overflow: hidden;

  .sessionsTable {
    width: 100%;
    height: calc(35vh - 50px);
    overflow-x: auto;
  }

  :global(.pagination-size-selector) {
    display: none;
  }

  :global(.MuiDataGrid-columnHeaders) {
    border-bottom: 0.5px solid
      var(--colors-sys-light-neutral-def-outline, #e6e6e6) !important;
    background: var(--colors-sys-light-neutral-surf, #f7f7f5) !important;
  }

  .sessionsTable {
    height: 100%;
    width: 100%;

    :global {
      .MuiDataGrid-root {
        border: none;
        font-family: var(--font-family-regular);
        height: 100% !important;
        overflow: hidden;
      }

      .MuiDataGrid-main {
        height: calc(100% - 56px) !important;
        overflow: hidden;
      }

      .MuiDataGrid-columnHeaders {
        min-height: 56px !important;
        max-height: 56px !important;
        line-height: 56px;
        border-bottom: 1px solid var(--color-border);
      }

      .MuiDataGrid-virtualScroller {
        height: 100% !important;
        overflow-y: auto !important;
      }

      .MuiDataGrid-row {
        min-height: 52px !important;
        max-height: 52px !important;
        border-bottom: 1px solid var(--color-border);
        cursor: default;

        &:hover {
          background-color: transparent;
        }
      }

      .MuiDataGrid-cell {
        min-height: 52px !important;
        max-height: 52px !important;
        line-height: 52px;
        padding: 0 16px;
        border-right: 1px solid var(--color-border);

        &:last-child {
          border-right: none;
        }
      }

      .MuiDataGrid-columnHeader {
        min-height: 56px !important;
        max-height: 56px !important;
        line-height: 56px;
        padding: 0 16px;
        border-right: 1px solid var(--color-border);
        font-weight: 600;

        &:last-child {
          border-right: none;
        }
      }

      .MuiDataGrid-columnSeparator {
        display: none;
      }

      .MuiDataGrid-footerContainer {
        display: none;
      }
    }
  }
}

.sessions_table {
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    border: 1px solid #e1e6ed;
    padding: 10px;
    text-align: left;
  }

  th {
    background: #f4f5f7;
  }
}

.data_table {
  & > div {
    overflow: visible !important;
    background-color: #ffffff;
    font-family: ManropeMedium !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
    color: #2e3042;

    & > div:nth-child(2) {
      overflow: visible !important;
      height: 100%;
      position: relative;
      z-index: 9;
    }
  }
  div[role="rowgroup"] div[role="row"] {
    & > div:last-child {
      // display: none;
    }
  }
}

.clientInfoContainer {
  width: 550px;
  border-radius: 12px;
  border: 1px solid var(--colors-sys-light-neutral-def-outline, #e6e6e6);
  background: var(--colors-dom-white, #fff);
  padding: 16px;

  .clientInfoHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
    background: #f7f7f5;
    margin: -16px -16px 24px -16px;
    padding: 16px;
    border-radius: 12px 12px 0 0;

    h2 {
      color: var(--colors-content-main, #1d2126);
      font-family: Manrope;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 25.2px;
      margin: 0;
    }

    .registrationDate {
      color: var(--color-text-secondary);
      font-size: 16px;
      opacity: 0.7;
    }
  }

  .clientInfoContent {
    .infoRow {
      display: flex;
      margin-bottom: 24px;
      padding-bottom: 8px;
      border-bottom: 1px solid
        var(--colors-sys-light-neutral-def-outline, #e6e6e6);

      &:last-child {
        margin-bottom: 24px;
      }

      .label {
        color: var(--colors-content-grey-30, #5b636d);
        font-family: Manrope;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .value {
        margin-left: auto;
        font-size: 16px;
        color: var(--color-text);
        text-align: right;

        &.active {
          color: #4caf50;
          display: flex;
          align-items: center;
          gap: 8px;

          &:before {
            content: "";
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: currentColor;
          }
        }
      }
    }
  }

  .expandButton {
    width: 100%;
    background: none;
    border: none;
    padding: 0;
    color: var(--color-text-secondary);
    opacity: 0.7;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    gap: 8px;

    .arrow {
      display: inline-block;
      width: 21px;
      height: 20px;
      background-image: url("data:image/svg+xml,%3Csvg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.7292 7.5001L10.4958 10.7334L7.2625 7.5001C6.9375 7.1751 6.4125 7.1751 6.0875 7.5001C5.7625 7.8251 5.7625 8.3501 6.0875 8.6751L9.9125 12.5001C10.2375 12.8251 10.7625 12.8251 11.0875 12.5001L14.9125 8.6751C15.2375 8.3501 15.2375 7.8251 14.9125 7.5001C14.5875 7.18343 14.0542 7.1751 13.7292 7.5001Z' fill='%236B6E80'/%3E%3C/svg%3E");
      transition: transform 0.2s ease;

      &.up {
        transform: rotate(180deg);
      }
    }
  }
}

.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  width: 100%;
}
