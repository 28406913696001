@import "../../assets/styles/global.scss";
.licenses_container {
  display: flex;
  flex-direction: column;
  & > div {
    margin-bottom: 20px;
    & > div {
      width: 100%;
    }
  }

  .textarea {
    min-height: 200px;
  }
}
.filter_container {
  display: flex;
  margin-bottom: 10px;
}
.order_status {
  justify-content: center !important;
}
.header_action_buttons > div > div {
  align-items: center;
  justify-content: center;
}
.menu-items {
  position: relative;
}
.delete_action_button {
  background-color: #e23d3d;
}
.disabled_btn {
  opacity: 0.5;
  cursor: no-drop;
}
.btn_suspend,
.btn_change,
.btn_restore,
.btn_transfer {
  background-color: #1d2126;
  color: #fff;
  &:hover {
    background-color: #2e3135;
  }
}
.action_buttons {
  display: flex !important;
  justify-content: center !important;
  margin-bottom: 10px;
  button {
    height: 45px;
    min-width: 105px;
  }
}
.custom_modal_container {
  min-width: 450px;
  p {
    text-align: center;
  }
}
.info_block_input_container {
  display: flex;
  min-height: 50px;
  align-items: center;
  margin: 10px 0px;
  justify-content: space-between;
  // & > div {
  //   max-width: 160px;
  // }
}
.sellers_table {
  border-radius: 12px !important;
  & > div {
    border-radius: 12px !important;
    overflow: visible !important;
    border-radius: 12px !important;
    background-color: #ffffff;
    font-family: ManropeMedium !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
    color: #2e3042;
    & > div:nth-child(2) {
      overflow: visible !important;
      height: 100%;
      position: relative;
      z-index: 9;
    }
  }
  div[role="rowgroup"] div[role="row"] {
    transition: background-color 0.2s ease;
    &:hover {
      background-color: #f5f5f5;
      cursor: pointer;
    }
    & > div:last-child {
      // display: none;
    }
  }
}
h2 {
  text-align: center;
}
.menu_actions {
  position: absolute;
  display: none;
  z-index: 99;
  background: #fff;
  top: 20px;
  text-transform: none;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  a {
    color: #000 !important;
  }
  & > div {
    padding: 5px 10px;
    &:hover {
      background-color: #f7f7f7;
    }
  }
  &.show {
    display: block;
  }
}
.filter_button {
  display: flex;
  padding: 9px 16px;
  justify-content: center;
  align-items: center;
  color: #8c969b;
  font-family: ManropeMedium;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  border-radius: 8px;
  border: 1px solid var(--colors-content-grey-border, #e1e6ed);
  background: var(--colors-sys-light-neutral-surf, #f7f7f5);
  text-transform: capitalize;
  color: #8c969b;
  cursor: pointer;
  border-radius: 4px;
  height: 40px;
  margin-right: 8px;
}
.filter_button_active {
  border-radius: 8px;
  background: var(--tab-selected, #ffa726);
  color: #fff;
}
.customer_page_bottom_container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}
.calendar_and_filters_container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.select_period {
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
  font-family: ManropeMedium;
  label {
    color: #5b636d;
  }
  div {
    font-family: ManropeMedium;
    color: #5b636d;
  }
  & > svg {
    margin-right: 10px;
  }
  fieldset {
    border: none;
  }
}
.add_license_btn {
  background-color: #e7f1ff !important;
  color: #2f80ed !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: normal !important;
  text-transform: unset !important;
  padding: 7px 8px !important;
}
.licenses_page_bottom_container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.export_csv {
  color: #2f80ed;
  font-size: 16px;
  font-family: ManropeMedium;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: none;
  svg {
    margin-left: 15px;
  }
}
.table_header {
  background-color: #f7f7f5 !important;
  display: block !important;
  > div > div > div > div {
    color: #5b636d;
    font-weight: 400;
    font-size: 16px;
  }
}

.table_cell {
  font-size: 16px;
  font-family: ManropeMedium;
  font-style: normal;
  font-weight: 400;
  line-height: 20.4px;
  text-transform: uppercase;
  overflow: visible !important;
  background-color: #fff;
  a {
    color: #2f80ed;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.table_cell_name {
  font-size: 16px;
  font-family: ManropeMedium;
  font-style: normal;
  font-weight: 400;
  line-height: 20.4px;

  a {
    color: #1d2126 !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.table_cell_date {
  font-size: 16px;
  font-family: ManropeMedium;
  font-style: normal;
  font-weight: 500;
  line-height: 20.4px;
  color: #8c969b;
}

.no_underline {
  text-decoration: none;
  color: #2e3042 !important;
  margin-left: 10px;
  font-family: ManropeMedium !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  color: #2e3042;
}
.no_underline_link {
  text-decoration: none;
}

.search_input {
  background-color: #fff;
}

.add_new_btn {
  height: 56px;
  margin-left: 10px;
}

.role_wrapper {
  display: flex;
  padding: 8px 18px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--colors-sys-light-neutral-icon-bg-light, #f7f2f2);
}

.custom_dialog {
  border-radius: 12px !important;
  .custom_modal_container {
    width: 600px;

    .modal_input {
      width: 50% !important;
    }
  }
}
