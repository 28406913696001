@import './palette.scss';
@import './constants.scss';
@import './common.scss';
@import './errors.scss';
@import './custom-scroll.scss';
@import "./sign-page-adaptive";
@import './palette.scss';
@import './constants.scss';

@import 'mixins/breakpoints.scss';
@import 'mixins/inputs.scss';
@import 'mixins/typography.scss';
@import 'mixins/buttons.scss';
@import 'mixins/form-section.scss';
@import 'mixins/flex.scss';
@import 'mixins/steper.scss';

// Override MUI input focus styles
.MuiInputBase-root {
  .MuiInputBase-input {
    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }
  }
  
  &.Mui-focused {
    fieldset {
      border: 1px solid #333333 !important;
    
  }
  }
  
  .MuiOutlinedInput-notchedOutline {
    // border-color: transparent !important;
  }
}