@import "../../../../assets/styles/global";

.from_labeled_input_wrapper {
    width: 100%;
    // max-width: 460px;
    margin-bottom: 16px;
    position: relative;
    .text_input {
        input {
            padding-left: 40px;
        }
    }
    .icon_left {
        position: absolute;
        top: 15px;
        left: 10px;
    }

    .icon_right {
        position: absolute;
        top: 15px;
        right: 10px;
        cursor: pointer;
    }
    &.styles_with_right_icon {
        .text_input {
            input {
                padding-right: 40px;
            }
        }
    }
    .error_message {
        color: #e23d3d;
        font-size: 14px;
        display: flex;
        align-items: center;
        margin-top: 5px;
        display: flex;
        border-radius: 5px;
        svg {
            margin-right: 5px;
        }
    }
    &.error {
        border-radius: 5px;
        fieldset {
            border: 2px solid #e23d3d !important;
        }
    }
}
