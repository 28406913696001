@mixin grid-main {
  display: grid;
  column-gap: 0px;
  grid-template-columns: 320px auto;
}

@mixin flex-justify-align-center {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}