@import "../../../assets/styles/global";

.ui-pagination {
  // display: flex;
  // justify-content: end;
  // margin-top: 10px;
  .full-width {
    width: 100%;
  }
  & > div {
    display: flex;
    flex-direction: row;
    width: 60px;
    justify-content: space-between;
  }
  // .pagination-item {
  //   font-size: 14px;
  //   border: none;
  //   padding: 3px;
  //   @media screen and (max-width: 500px) {
  //     font-size: 12px !important;
  //   }
  //   text-align: center;
  // }

  // .pagination-item > a {
  //   display: flex;
  //   justify-content: center;
  //   flex-direction: row;
  //   align-items: center;
  //   min-width: 23px;
  //   height: 23px;
  //   margin-top: 5px;
  //   @media screen and (max-width: 500px) {
  //     min-width: 24px;
  //     height: 24px;
  //     padding: 0px;
  //   }
  //   text-align: center;
  //   line-height: 24px;
  //   border: none;
  //   padding: 6px;
  //   color: #323232;
  // }

  .pagination-item > a:hover {
    color: #8c969b;
    text-decoration: none;
  }

  @media screen and (min-width: 500px) {
    .last {
      padding-left: 14px;
    }
  }

  .first,
  .last {
    @media screen and (min-width: 501px) {
      width: 80px;
    }
    height: 16px;
  }
  .first.pagination-item,
  .last.pagination-item {
    display: none;
  }
  .current,
  .page-style {
    min-width: 23px;
    height: 23px;
    @media screen and (max-width: 500px) {
      min-width: 23px !important;
      height: 23px !important;
    }
    border: none;
    margin-left: 3px;
    margin-right: 3px;
  }

  .current {
    background-color: #fff;
    border-radius: 100%;
  }

  .current > a {
    color: #8c969b;
  }
  .pagination-item a {
    padding: 0;
    min-width: 10px;
    height: 10px;
    font-size: 16px;
    font-family: ManropeMedium;
  }
  .current > a:hover {
    color: #8c969b;
    text-decoration: none;
  }

  // .next,
  // .prev {
  //   position: relative;
  //   top: 0px;
  //   margin-left: 6px;
  //   margin-right: 6px;
  // }

  @media screen and (max-width: 500px) {
    .next {
      left: -8px;
    }
  }

  // .next > a,
  // .prev > a {
  //   padding: 0px;
  //   min-width: 0px;
  //   height: auto;
  // }

  // .next > a {
  //   height: 10px;
  //   width: 10px;
  //   border: 1px solid #323232;
  //   border-width: 1px 1px 0 0;
  //   transform: rotate(45deg);
  // }

  // .next > a:after {
  //   content: "";
  //   display: block;
  //   height: 10px;
  //   width: 10px;
  //   border: 1px solid #323232;
  //   border-width: 1px 1px 0 0;
  //   // transform: translate(6px, -6px);
  // }

  // .next-one > a:after {
  //   content: "";
  //   display: block;
  //   height: 10px;
  //   width: 10px;
  //   border: 1px solid #323232;
  //   border-width: 1px 1px 0 0;
  // }

  // .prev > a {
  //   height: 10px;
  //   width: 10px;
  //   border: 1px solid #323232;
  //   border-width: 1px 1px 0 0;
  //   transform: rotate(-135deg);
  // }

  // .prev > a:after {
  //   content: "";
  //   display: block;
  //   height: 10px;
  //   width: 10px;
  //   border: 1px solid #323232;
  //   border-width: 1px 1px 0 0;
  //   // transform: translate(6px, -6px);
  // }

  // .prev-one > a:after {
  //   content: "";
  //   display: block;
  //   height: 10px;
  //   width: 10px;
  //   border: 1px solid #323232;
  //   border-width: 1px 1px 0 0;
  // }

  // .next > a:hover,
  // .prev > a:hover,
  // .next > a:hover::after,
  // .prev > a:hover::after {
  //   border: 1px solid #8c969b;
  //   border-width: 1px 1px 0 0;
  // }

  // .disabled > a,
  // .disabled > a:hover {
  //   color: #cccccc;
  //   cursor: default;
  // }

  // .next.disabled > a,
  // .next.disabled > a::after,
  // .next.disabled > a:hover,
  // .next.disabled > a:hover::after,
  // .prev.disabled > a,
  // .prev.disabled > a::after,
  // .prev.disabled > a:hover,
  // .prev.disabled > a:hover::after {
  //   border: 1px solid #ccc;
  //   border-width: 1px 1px 0 0;
  // }
}
.pagination-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
}
.pagination-info {
  margin-right: 20px;
  color: #8c969b;
}
.prev.pagination-item a {
  background-image: url("../../../assets//media/images/arrow-left.png");
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
}

.next.pagination-item a {
  background-image: url("../../../assets//media/images/arrow-right.png");
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
}

.next.pagination-item.disabled a,
.prev.pagination-item.disabled a {
  opacity: 0.4;
  cursor: default;
}
.select-page-size {
  margin-right: 30px;
  
  &.hidden {
    display: none;
  }
  
  label {
    margin-right: 10px;
    color: #8c969b;
  }
  fieldset {
    display: none;
  }
  div {
    border: none !important;
    padding: unset;
    font-family: ManropeMedium;
    color: #8c969b;
  }
}
ul li {
  font-family: ManropeMedium !important;
}
