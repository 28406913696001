@import "../../../assets/styles/global";

.setting_content_wrapper {
  border-bottom: 0.5px solid #e1e6ed;
  .setting_info_text {
    font-family: ManropeMedium;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22.4px;
    margin-bottom: 24px;
  }
}

.page_title {
  font-family: Onest !important;
  font-size: 24px !important;
  font-weight: 400 !important;
  line-height: 34px !important;
  letter-spacing: 0em !important;
  color: #1d2126;
}
.settings_drawer_wrapper {
  padding: 30px;
  width: 300px;
  display: flex;
  flex-direction: column;
  height: 100%;

  .drawer_title_container {
    display: flex;
    justify-content: space-between;
    .drawer_title {
      color: #000;
      font-family: ManropeMedium;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 33.6px;
      margin-bottom: 40px;
    }
    .drawer_close_icon {
      margin-top: -15px;
      height: 55px;
      svg {
        width: 40px;
        height: 40px;
      }
    }
  }
  .drawer_content {
    flex-grow: 1;
    flex-direction: column;
    display: flex;
  }
  .buttons {
    button {
      width: 100%;
      background: #2f80ed;
      color: #fff;
      font-family: ManropeMedium;
      font-size: 16px;
      font-weight: 500;
      text-transform: capitalize;
      &.no_margin {
        span {
          margin-left: 5px;
        }
        svg {
          margin-left: 0px;
          color: #fff;
        }
      }
      &.saved {
        background-color: #20b053;
      }
      svg {
        margin-left: 5px;
        path {
          fill: #ebf0f7;
        }
      }
    }
  }
}
.breadcrumbs {
  font-family: ManropeMedium;
  margin-bottom: 10px !important;
  p {
    font-family: ManropeMedium;
  }
  a {
    text-decoration: none;
    color: #2f80ed;
  }
}

.top_nav_bar_wrapper {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  // cursor: pointer;

  .top_bar_page_name {
    font-family: ManropeMedium;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 33.6px;
  }

  .top_bar_page_user_wrapper {
    display: flex;
    align-items: center;
    span {
      font-family: ManropeMedium;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22.4px;
      margin-right: 8px;
    }
    .settings_icon_wrapper {
      margin-left: 12px;
      cursor: pointer;
      display: flex;
    }
    .user_name_info_wrapper {
      display: flex;
      align-items: flex-start;
      // cursor: pointer;
      padding: 10px;
      flex-direction: column;
      // margin-bottom: 20px;
      // &:hover {
      //   border-radius: 10px;
      //   box-shadow: -2px 7px 7px 0px #605c5c12;

      //   .exit_btn {
      //     display: block;
      //   }
      // }

    //   .exit_btn {
    //     display: none;
    //     color: #2f80ed;
    //     padding: 5px 10px;
    //     border: 2px solid #2f80ed;
    //     border-radius: 5px;
    //     &:hover {
    //       background: #f9f9f9;
    //     }
    //   }

      .user_login {
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }
  }
}

.custom_modal_container {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  min-width: 550px;
  position: relative;
  padding: 20px;
  font-family: ManropeMedium;

  .cancel_button {
    background-color: #e23d3d !important;
    color: #fff !important;
    border: none !important;
    min-width: 215px;
    // &:hover {
    //     // .size-l.btn-secondary:hove
    //     background-color: #b82222 !important;
    // }
  }
  .setting_modal_title {
    display: flex;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
    font-family: ManropeMedium;
    color: #1d2126;
  }
  .modal_content {
    width: 100%;
    box-sizing: border-box;
    padding: 0px;
    margin-bottom: 20px;
    p {
      font-family: ManropeMedium;
    }
  }

  .action_buttons {
    button {
      padding: 19px 22px;
    }
  }

  .approve_button {
    background-color: #1d2126 !important;
    color: #fff !important;
    border: none !important;
    min-width: 215px;
    &:hover {
      // .size-l.btn-secondary:hove
      background-color: #35383b !important;
    }
  }

  .cancel_button {
    color: #8c969b !important;
    border: none !important;
    &:hover {
      // .size-l.btn-secondary:hove
      background-color: #35383b !important;
    }
  }
}
.logout_button {
  background-color: #e23d3d !important;
  color: #fff !important;
  border: none !important;
}

.notification_icon_wrapper {
    cursor: pointer;
    display: flex;
    align-items: center;
}
h3{
  font-size: 18px;
  text-align: center;
}
.merchant_info{
  padding: 10px 20px;
  & > div:first-child{
    font-size: 14px;
    color: #9b9b9b;
  }
  &:nth-child(2){
    font-size: 18px; 
  }
}

.info_block_input_container {
  display: flex;
  min-height: 50px;
  align-items: center;
  margin: 10px 0px;
  justify-content: space-between;
  & > div {
    max-width: 50%;
  }
}