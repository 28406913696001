@import "../../assets/styles/global.scss";
.licenses_container {
  display: flex;
  flex-direction: column;
  & > div {
    margin-bottom: 20px;
    & > div {
      width: 100%;
    }
  }

  .textarea {
    min-height: 200px;
  }
}
.filter_container {
  display: flex;
  margin-bottom: 10px;
}
.order_status {
  justify-content: center !important;
}
.header_action_buttons > div > div {
  align-items: center;
  justify-content: center;
}
.menu-items {
  position: relative;
}
.delete_action_button {
  background-color: #e23d3d;
}
.disabled_btn {
  opacity: 0.5;
  cursor: no-drop;
}
.btn_suspend,
.btn_change,
.btn_restore,
.btn_transfer {
  background-color: #1d2126;
  color: #fff;
  &:hover {
    background-color: #2e3135;
  }
}
.action_buttons {
  display: flex !important;
  justify-content: center !important;
  margin-bottom: 10px;
  button {
    height: 45px;
    min-width: 105px;
  }
}
.custom_modal_container {
  min-width: 450px;
  p {
    text-align: center;
  }
}
.info_block_input_container {
  display: flex;
  min-height: 50px;
  align-items: center;
  margin: 10px 0px;
  justify-content: space-between;
  & > div {
    max-width: 160px;
  }
}
.sellers_table {
  border-radius: 12px !important;

  :global(.MuiDataGrid-columnHeaders) {
    background-color: #f7f7f5 !important;
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
  }

  & > div {
    border-radius: 12px !important;
    overflow: visible !important;
    background-color: #ffffff;
    font-family: ManropeMedium !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
    color: #2e3042;
    & > div:nth-child(2) {
      overflow: visible !important;
      height: 100%;
      position: relative;
      z-index: 9;
    }
  }
  div[role="rowgroup"] div[role="row"] {
    cursor: pointer !important;
    border-bottom: 1px solid #e6e6e6;

    &:hover {
      background-color: #f7f7f5;
    }
  }
}
h2 {
  text-align: center;
}
.menu_actions {
  position: absolute;
  display: none;
  z-index: 99;
  background: #fff;
  top: 20px;
  text-transform: none;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  a {
    color: #000 !important;
  }
  & > div {
    padding: 5px 10px;
    &:hover {
      background-color: #f7f7f7;
    }
  }
  &.show {
    display: block;
  }
}
.filter_buttons {
  display: flex;
  gap: 8px;
  margin: 16px 0;
}
.filter_button {
  display: flex;
  padding: 9px 16px;
  justify-content: center;
  align-items: center;
  color: #8c969b;
  font-family: ManropeMedium;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  border-radius: 8px;
  border: 1px solid var(--colors-content-grey-border, #e1e6ed);
  background: var(--colors-sys-light-neutral-surf, #f7f7f5);
  text-transform: capitalize;
  color: #8c969b;
  cursor: pointer;
  border-radius: 4px;
  height: 40px;
  margin-right: 8px;

  &:hover {
    background: #f3f4f6;
  }

  &.active {
    background: #f59e0b;
    border-color: #f59e0b;
    color: #ffffff;
  }
}
.customer_page_bottom_container {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  .search_container {
    display: flex;
  }
}
.calendar_and_filters_container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.select_period {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 8px;

  label {
    color: #2e3042;
    font-family: ManropeMedium;
    font-size: 14px;
  }

  :global(.MuiSelect-select) {
    padding: 8px 14px;
    min-height: unset !important;
    height: 24px;
    display: flex;
    align-items: center;
  }

  :global(.MuiOutlinedInput-root) {
    height: 40px;
    min-height: unset;
    background-color: transparent;

    fieldset {
      border: none;
    }

    &:hover fieldset {
      border: none;
    }

    &.Mui-focused fieldset {
      border: none;
    }
  }

  :global(.MuiInputBase-root) {
    border-radius: 8px;
    background-color: transparent;
  }

  :global(.MuiMenuItem-root) {
    font-family: ManropeMedium;
    font-size: 14px;
    min-height: 32px;
  }
}
.dates {
  color: var(--text-secondary);
  font-size: 14px;
  margin-left: 8px;
}
.add_license_btn {
  background-color: #e7f1ff !important;
  color: #2f80ed !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: normal !important;
  text-transform: unset !important;
  padding: 7px 8px !important;
}
.licenses_page_bottom_container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.export_csv {
  color: #2f80ed;
  font-size: 16px;
  font-family: ManropeMedium;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: none;
  svg {
    margin-left: 15px;
  }
}
.table_header {
  background-color: #f7f7f5 !important;
  display: block !important;
  > div > div > div > div {
    color: #5b636d;
    font-weight: 400;
    font-size: 16px;
  }
}

.table {
  border-radius: 12px !important;
  height: 588px !important;

  :global(.MuiDataGrid-columnHeaders) {
    background-color: #f7f7f5 !important;
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
  }

  & > div {
    border-radius: 12px !important;
    overflow: visible !important;
    background-color: #ffffff;
    font-family: ManropeMedium !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
    color: #2e3042;
    & > div:nth-child(2) {
      overflow: visible !important;
      height: 100%;
      position: relative;
      z-index: 9;
    }
  }

  :global(.MuiDataGrid-row) {
    cursor: pointer !important;
    border-bottom: 1px solid #e6e6e6;

    &:hover {
      background-color: #f7f7f5;
    }
  }

  div[role="rowgroup"] div[role="row"] {
    cursor: pointer !important;
    border-bottom: 1px solid #e6e6e6;

    &:hover {
      background-color: #f7f7f5;
    }
  }

  :global(.MuiDataGrid-cell) {
    border-bottom: none !important;
    font-family: ManropeMedium !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
    color: #2e3042;
    padding: 16px !important;
  }

  :global(.MuiDataGrid-columnHeader) {
    border-right: none !important;
    padding: 16px !important;
  }

  :global(.MuiDataGrid-columnHeaderTitle) {
    font-family: ManropeMedium !important;
    color: #5b636d;
    font-weight: 400;
    font-size: 16px;
  }
}

.table_cell {
  font-size: 16px;
  font-family: ManropeMedium;
  font-style: normal;
  font-weight: 400;
  line-height: 20.4px;
  text-transform: uppercase;
  overflow: visible !important;
  a {
    color: #2f80ed;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.table_cell_name {
  font-size: 16px;
  font-family: ManropeMedium;
  font-style: normal;
  font-weight: 400;
  line-height: 20.4px;

  a {
    color: #1d2126 !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.table_cell_date {
  font-size: 16px;
  font-family: ManropeMedium;
  font-style: normal;
  font-weight: 500;
  line-height: 20.4px;
  color: #8c969b;
}

.no_underline {
  text-decoration: none;
  color: #2e3042 !important;
  margin-left: 10px;
  font-family: ManropeMedium !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  color: #2e3042;
}
.no_underline_link {
  text-decoration: none;
}

.search_input_wrapper {
  width: 60%;
  .search_input {
    background-color: #fff;
    width: 100%;
  }
}

.reset_btn {
  background-color: #e6e6e6 !important;
  color: #333333 !important;
  height: 56px;

  svg {
    path {
      fill: #333333;
    }
  }
}

.truncate_text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.role_wrapper {
  display: flex;
  padding: 8px 18px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--colors-sys-light-neutral-icon-bg-light, #f7f2f2);
}

.status {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-family: ManropeMedium;

  &::before {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-block;
  }

  &.active {
    color: #4caf50;
    &::before {
      background-color: #4caf50;
    }
  }

  &.inactive {
    color: #9e9e9e;
    &::before {
      background-color: #9e9e9e;
    }
  }
}

.status_select {
  min-width: 120px;
  margin-right: 16px;

  :global {
    .MuiOutlinedInput-root {
      border-radius: 8px;
    }
  }
}

.modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.import_modal {
  background: white;
  border-radius: 12px;
  padding: 24px;
  position: relative;
  width: 616px;
  max-width: 90vw;
  transition:
    width 0.3s ease,
    height 0.3s ease;

  &.with_preview {
    width: 1040px;
    height: 534px;
    padding: 0;
    display: flex;
    flex-direction: column;
    border-radius: 12px !important;
  }
}

.modal_header {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .close_button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    transition: all 0.2s;
    margin-left: 16px;

    svg {
      width: 30px;
      height: 30px;

      path {
        transition: fill 0.2s;
      }
    }

    &:hover {
      background: #f3f4f6;

      svg path {
        fill: #1f2937;
      }
    }
  }
}

.modal_title {
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    color: #000;
    text-align: center;
    font-family: Onest !important;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 33.6px;
  }
}

.modal_content {
  padding: 24px;
  flex: 1;
  overflow-y: auto;

  .with_preview & {
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
}

.modal_footer {
  padding: 16px 24px;
  display: flex;
  justify-content: center;
  gap: 12px;
  background: white;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;

  button {
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s;
  }

  .cancel_button {
    padding: 18px 22px 19px 22px;
    color: var(--colors-content-dop, #8c969b);
    text-align: center;
    font-family: Onest;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22.4px; /* 140% */
    cursor: pointer;
  }

  .submit_button {
    padding: 18px 22px 19px 22px;
    color: white;
    border-radius: 4px;
    background: var(--colors-content-main, #1d2126);
    cursor: pointer;
    &.disabled {
      opacity: 0.6;
      cursor: not-allowed;
      pointer-events: none;
    }
  }
}

.dialog_actions {
  padding: 16px;
  display: flex !important;
  gap: 24px;
  justify-content: center !important;
  align-items: center !important;

  .cancel_link {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      opacity: 0.8;
    }
  }
}

.drop_zone {
  padding: 28px 16px;
  border-radius: var(--Components-Upload-Global-borderRadiusLG, 8px);
  border: 1px dashed var(--colors-sys-light-neutral-def-outline, #e6e6e6);
  background: var(--colors-sys-light-neutral-surf, #f7f7f5);
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  display: flex;
  flex-direction: column;

  &.dragging {
    border-color: #f59e0b;
    background: #fef3c7;
  }

  .upload_icon {
    margin: 0;
    flex-shrink: 0;
  }

  .upload_text {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin: 0;

    .click_here {
      color: #f8991c;
    }

    div {
      margin: 0;
      color: var(--Color-Text-Primary, #161718);
      text-align: center;
      font-family: Manrope;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 12px;
    }

    span {
      margin: 0;
      color: var(--Color-Text-Primary, #161718);
      text-align: center;
      font-family: Manrope;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .file_info {
      color: var(--Color-Text-Placeholder, #848891);
      text-align: center;
      font-family: Manrope;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .click_here {
    color: #f59e0b;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: #d97706;
    }
  }

  &:hover {
    border-color: #f59e0b;
    background: #fffbeb;
  }
}

.preview_container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .uploaded_file {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    background: #f7f7f5;
    margin-bottom: 16px;
    border-radius: 8px;

    .uploaded_file_name_wrapper {
      display: flex;
      align-items: center;
    }

    .file_actions {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .preview_button {
      padding: 4px 12px;
      color: #1d2126;
      font-family: Manrope;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-decoration-line: underline;
      text-decoration-style: solid;
      text-decoration-skip-ink: none;
      text-decoration-thickness: auto;
      text-underline-offset: auto;
      text-underline-position: from-font;
      cursor: pointer;
      font-size: 14px;
      transition: all 0.2s;

      &:hover {
        text-decoration-line: none;
      }
    }

    .remove_button {
      background: none;
      border: none;
      color: #6b7280;
      cursor: pointer;
      font-size: 18px;
      padding: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      border-radius: 4px;

      &:hover {
        background: #f3f4f6;
        color: #1f2937;
      }
    }
  }
}

.csv_preview {
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  overflow: hidden;
  margin-top: 10px;

  .table_wrapper {
    max-height: 400px;
    overflow: auto;
  }

  .table {
    width: 100%;
    border-collapse: collapse;
    background: #fff;
    font-family: ManropeMedium !important;
    font-size: 14px !important;
  }

  .row {
    display: flex;
    border-bottom: 1px solid #e5e7eb;

    &.header_row {
      background-color: #f7f7f5 !important;
      font-weight: 500;
      position: sticky;
      top: 0;
      z-index: 1;
    }

    .row_number {
      width: 40px;
      min-width: 40px;
      padding: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f7f7f5;
      border-right: 1px solid #e5e7eb;
      color: #2e3042;
    }

    .cell {
      padding: 12px;
      flex: 1;
      min-width: 150px;
      display: flex;
      align-items: center;
      color: #2e3042;

      &:not(:last-child) {
        border-right: 1px solid #e5e7eb;
      }
    }
  }
}

.logs_container {
  padding: 16px 24px;
  overflow-y: auto;
  flex: 1;
}

.log_entry {
  padding: 16px 0;
  border-bottom: 1px solid #e5e7eb;

  &:last-child {
    border-bottom: none;
  }
}

.log_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;

  h3 {
    margin: 0;
    font-size: 16px;
    color: #111827;
  }

  span {
    color: #6b7280;
    font-size: 14px;
  }
}

.log_content {
  display: flex;
  align-items: center;
  gap: 6px;
  color: #4b5563;
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;

  .icon {
    font-size: 16px;
    color: #6b7280;
    flex-shrink: 0;
  }

  .user {
    font-weight: 500;
    color: #111827;
    flex-shrink: 0;
  }

  span:last-child {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.load_more_button {
  width: 100%;
  padding: 8px;
  margin-top: 16px;
  background: #f3f4f6;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  color: #4b5563;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s;

  &:hover:not(:disabled) {
    background: #e5e7eb;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}

.logs_button_container {
  margin-left: 16px;
  position: relative;
  display: inline-block;
  border-left: 2px solid #e1e6ed;

  .logs_button {
    margin-left: 12px;
    cursor: pointer;
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    background: var(--colors-sys-light-prim-prim-con, #e8e7e7);
    width: 32px;
    height: 32px;
  }
}

.logs_popup {
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  background: white;
  border-radius: 8px;
  width: 400px;
  max-height: 500px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow:
    0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border: 1px solid #e5e7eb;
  z-index: 1000;

  &::before {
    content: "";
    position: absolute;
    top: -8px;
    right: 20px;
    width: 16px;
    height: 16px;
    background: white;
    transform: rotate(45deg);
    border-left: 1px solid #e5e7eb;
    border-top: 1px solid #e5e7eb;
  }
}

.logs_header {
  padding: 12px 16px;
  border-bottom: 1px solid #e5e7eb;
  background: var(--colors-dom-main, #f4f5f7);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 1;

  h2 {
    margin: 0;
    font-size: 16px;
    color: #1d2126;
    font-weight: 500;
  }
}

.close_button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #6b7280;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 4px;

  &:hover {
    background: #f3f4f6;
    color: #111827;
  }
}

.logs_container {
  padding: 8px 16px;
  overflow-y: auto;
  max-height: 400px;
}

.log_entry {
  padding: 12px 0;
  border-bottom: 1px solid #e5e7eb;

  &:last-child {
    border-bottom: none;
  }
}

.log_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;

  h3 {
    margin: 0;
    font-size: 14px;
    color: #111827;
    font-weight: 500;
  }

  span {
    color: #6b7280;
    font-size: 12px;
  }
}

.log_content {
  display: flex;
  align-items: center;
  gap: 6px;
  color: #4b5563;
  font-size: 13px;

  .icon {
    font-size: 16px;
    color: #6b7280;
  }

  .user {
    font-weight: 500;
    color: #111827;
  }
}

.load_more_button {
  width: 100%;
  padding: 6px;
  margin: 8px 0;
  background: #f3f4f6;
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  color: #4b5563;
  cursor: pointer;
  font-size: 13px;
  transition: all 0.2s;

  &:hover:not(:disabled) {
    background: #e5e7eb;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}

.bottom_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .import_button {
    margin-top: 20px;
    display: flex;
    padding: 12px 22px 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    border-radius: 12px;
    background: var(--colors-sys-light-prim-prim-con, #e8e7e7);
    cursor: pointer;

    span {
      color: var(--colors-sys-light-prim-on-prim-con, #333);
      text-align: center;
      font-family: Manrope;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22.4px; /* 140% */
    }
  }
}

.filter_buttons_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .delete_button {
    display: flex;
    padding: 8px 16px 8px 12px;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    background: var(--colors-accent-red, #e23d3d);
    color: var(--colors-sys-light-on-prim-btn, #f5f8fc);
    text-align: center;
    font-family: Onest;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.content_visible {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-width: 400px;

  :global {
    .MuiFormControl-root {
      width: 100%;
      margin: 0;
    }

    .MuiInputBase-root {
      width: 100%;
    }

    .MuiInputLabel-root {
      position: relative;
      transform: none;
      width: auto;
      max-width: none;
      padding: 0;
      margin-bottom: 8px;
    }

    .MuiInputLabel-shrink {
      transform: none;
    }

    .MuiOutlinedInput-root {
      margin-top: 0;
    }
  }
}

.date_field_container {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .date_label {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.87);
  }
}

.filters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 24px;

  .search {
    min-width: 300px;

    :global(.MuiOutlinedInput-root) {
      background-color: #ffffff;
      border-radius: 8px;
      height: 40px;

      &:hover fieldset {
        border-color: #e6e6e6;
      }

      &.Mui-focused fieldset {
        border-color: #2196f3;
      }

      svg {
        width: 20px;
        height: 20px;
        margin-left: 12px;
        color: #6b7280;

        path {
          fill: currentColor;
        }
      }
    }

    :global(.MuiOutlinedInput-input) {
      padding: 8px 14px;
      font-family: ManropeMedium;
      font-size: 14px;

      &::placeholder {
        color: #6b7280;
        opacity: 1;
      }
    }
  }

  .search_icon {
    width: 20px;
    height: 20px;
    margin-left: 12px;
    color: #6b7280;

    path {
      fill: currentColor;
    }
  }

  div {
    display: flex;

    .select_period {
      display: flex;
      flex-direction: row;
    }
  }
}

.export_button {
  display: flex;
  padding: 12px 18px 12px 14px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 12px;
  background: var(--colors-sys-light-prim-prim-btn, #333);
  cursor: pointer;
  margin-left: 10px;

  span {
    color: var(--colors-sys-light-prim-on-prim-btn, #fff);
    text-align: center;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.promo_table_wrapper {
  margin-bottom: 20px;
}

.table {
  border-radius: 12px !important;

  :global(.MuiDataGrid-columnHeaders) {
    background-color: #f7f7f5 !important;
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
  }

  & > div {
    border-radius: 12px !important;
    overflow: visible !important;
    background-color: #ffffff;
    font-family: ManropeMedium !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
    color: #2e3042;
    & > div:nth-child(2) {
      overflow: visible !important;
      height: 100%;
      position: relative;
      z-index: 9;
    }
  }
}

.footer_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  :global(.MuiPagination-root) {
    margin: 0 auto;
  }
}

.import_button {
  margin-top: 20px;
  display: flex;
  padding: 12px 22px 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 12px;
  background: var(--colors-sys-light-prim-prim-con, #e8e7e7);
  cursor: pointer;

  span {
    color: var(--colors-sys-light-prim-on-prim-con, #333);
    text-align: center;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22.4px; /* 140% */
  }
}

.operation_type {
  padding: 6px 12px;
  border-radius: 4px;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  width: fit-content;
}

.operation_type_signin {
  background-color: #f3f4f6;
  color: #374151;
}

.operation_type_card {
  background-color: #fff7ed;
  color: #c2410c;
}

.operation_type_deposit {
  background-color: #fef3f2;
  color: #b42318;
}

.operation_type_account {
  background-color: #eff6ff;
  color: #1d4ed8;
}

.operation_type_credit {
  background-color: #f0fdf4;
  color: #15803d;
}

.operation_type_default {
  background-color: #f3f4f6;
  color: #374151;
}

.operation_type_new_deposit {
  background-color: #f0fdf4;
  color: #15803d;
}

.custom_period_content {
  padding: 20px;
  min-width: 300px;

  .date_picker_wrapper {
    margin-bottom: 20px;
    
    &:last-child {
      margin-bottom: 0;
    }

    label {
      display: block;
      margin-bottom: 8px;
      font-size: 14px;
      color: #666;
    }
  }
}


.cancel_button {
  border: none;
  background: transparent;
  padding: 18px 22px 19px 22px;
  justify-content: center;
  align-items: center;
  color: var(--colors-content-dop, #8c969b);
  text-align: center;
  font-family: Onest;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22.4px; /* 140% */
  cursor: pointer;
}

.atms_table {
  border-radius: 12px !important;
  
  :global(.MuiDataGrid-columnHeaders) {
    background: transparent !important;
  }

  :global(.MuiDataGrid-columnHeadersInner) {
    background: #F7F7F5 !important;
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
  }

  & > div {
    border-radius: 12px !important;
    overflow: visible !important;
    background-color: #ffffff;
    font-family: ManropeMedium !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
    color: #2e3042;
    
    & > div:nth-child(2) {
      overflow: visible !important;
      height: 100%;
      position: relative;
      z-index: 9;
    }
  }
}