.error-label {
  display: flex;
  align-items: center;
  text-transform: uppercase;

  & > svg {
    margin-right: 5px;
  }
  &.red {
    color: #e23d3d;
    svg {
      fill: #e23d3d;
    }
  }
  &.blue {
    color: #2f80ed;
    svg {
      fill: #2f80ed;
    }
  }
  &.green {
    color: #20b053;
    svg {
      fill: #20b053;
    }
  }
  &.yellow {
    color: #ff9700;
    svg {
      fill: #ff9700;
    }
  }
  &.orange {
    color: #ff9700;
    svg {
      fill: #ff9700;
    }
  }
  &.gray {
    color: #8c969b;
    svg {
      fill: #8c969b;
    }
  }
  &.dark-gray {
    color: #515353;
    svg {
      fill: #515353;
    }
  }
  &.assigned-orange {
    // padding: 18px 8px 18px 8px;
    padding-left: 8px;
    padding-right: 8px;
    display: flex;
    justify-content: center;
    max-height: 32px;
    background: #fff5e7;
    max-width: 89px;
    border-radius: 6px;
    text-align: center;
    color: #ff9700;
    svg {
      display: none;
      fill: transparent;
    }
  }

  &.assigned-dop {
    padding-left: 8px;
    padding-right: 8px;
    display: flex;
    justify-content: center;
    max-height: 32px;
    background: #f4f5f7;
    max-width: 89px;
    border-radius: 6px;
    text-align: center;
    color: #8c969b;
    svg {
      display: none;
      fill: transparent;
    }
  }
}
