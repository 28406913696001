.modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: #ffffff;
  border-radius: 16px;
  width: 100%;
  max-width: 500px;
  padding: 24px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
}

.modal_header {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .close_button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      opacity: 0.8;
    }
  }
}

.modal_content {
  margin-bottom: 24px;
  display: flex;
  padding: 0px 28px 8px 28px;
  flex-direction: column;
  align-items: center;
  gap: 14px;
  align-self: stretch;

  h2 {
    color: var(--colors-sys-light-neutral-on-surf-con, #2e3042);
    text-align: center;
    font-family: Manrope;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 33.6px; /* 140% */
    margin: 0;
  }

  span {
    color: var(--colors-sys-light-neutral-on-surf-con-var, #6b6e80);
    text-align: center;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
  }
}

.modal_footer {
  display: flex;
  justify-content: center;
  gap: 12px;

  button {
    padding: 8px 24px;
    border-radius: 8px;
    font-family: Manrope;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      opacity: 0.9;
    }
  }

  .delete_button {
    display: flex;
    padding: 18px 22px 19px 22px;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    background: var(--colors-accent-red, #e23d3d);
  }

  .cancel_button {
    border: none;
    background: transparent;
    padding: 18px 22px 19px 22px;
    justify-content: center;
    align-items: center;
    color: var(--colors-content-dop, #8c969b);
    text-align: center;
    font-family: Onest;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22.4px; /* 140% */
  }
}
