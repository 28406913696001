@import "../../../assets/styles/global";

.button_wrapper {
    width: 100%;
    max-width: 418px;
}

.table_container {
    display: flex;
    justify-content: center;
    align-items: center;
    & > div > div > div {
        background: transparent !important;
    }
    &.is_loading{
        min-height: 300px;
    }

    :global(.MuiDataGrid-root) :global(.MuiDataGrid-row:hover), 
    :global(.MuiDataGrid-root) :global(.MuiDataGrid-row.Mui-hovered),
    :global(.MuiDataGrid-root) :global(.MuiDataGrid-row.Mui-selected:hover) {
        background-color: rgba(0, 0, 0, 0.04);
    }
}