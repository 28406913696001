$desktop-width: 960px;
$tablet-width: 760px;
$widget-width: 319px;
$min-mobile-width: 320px;
$max-mobile-width: 481px;


@mixin desktop-and-above {
  @media (min-width: #{ $desktop-width }) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{ $desktop-width }) {
    @content;
  }
}

@mixin max-mobile-width {
  @media (max-width: #{ $max-mobile-width }) {
    @content;
  }
}

@mixin tablet-to-desktop {
  @media (max-width: #{ $desktop-width }) {
    @content;
  }
}

@mixin widget {
  @media (max-width: #{ $widget-width }) {
    @content;
  }
}
