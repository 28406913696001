@import "../../../assets/styles/global";

.btn-delete {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;
  padding: 12px 22px 12px 16px;
  background-color: #e23d3d;
  color: #fff;
  border: none;
  outline: none;
  height: fit-content;

  span {
    font-size: 16px;
    line-height: 22.4px;
    font-weight: 500;
    margin-left: 5px;
  }

  svg {
    path {
      fill: #fff;
    }
  }
}

.btn-primary {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 12px;
  padding: 8px 16px 8px 12px;
  background-color: #333333;
  color: #fff;
  border: none;
  outline: none;
  height: 100%;
  width: max-content;

  span {
    font-size: 16px;
    line-height: 22.4px;
    font-weight: 500;
    margin-left: 5px;
  }

  svg {
    path {
      fill: #fff;
    }
  }

  &:disabled {
    background-color: #f4f5f7;
    border: 1.5px solid #e1e6ed;
    color: #ccd3dd;
    border: none;
    padding: 8px 16px 8px 12px;
    outline: none;
    height: 100%;
    cursor: no-drop;
    span {
      cursor: no-drop;
    }
    svg {
      path {
        fill: #ccd3dd !important;
      }
    }
  }
}

.btn-ghost {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;
  padding: 8px 16px 8px 12px;
  background-color: transparent;
  color: #333333;
  border: none;
  outline: none;
  height: 100%;
  font-family: ManropeMedium;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: all 0.3s ease;

  svg {
    path {
      fill: #a1abb2 !important;
    }
  }

  span {
    font-size: 16px;
    line-height: 22.4px;
    font-weight: 500;
    margin-left: 5px;
  }

  &.disabled {
    opacity: 0.3;
    // cursor: no-drop;
    span {
      // cursor: no-drop;
    }
  }
}
